<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="12" sm="8" class="d-flex">
          <div class="text-h3 text-truncate">{{ship.shipName || ship.name}}</div>
          <v-spacer></v-spacer>
          <div class="text-h5" style="align-self: flex-end">owner</div>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex">
          <v-hover v-slot="{ hover }">
            <div :class="{ 'custom-anchor': hover }" class="text-h5" style="cursor: pointer;align-self: flex-end;" @click="userDetail(ship.userId)">{{ship.userName}}</div>
          </v-hover>
        </v-col>
      </v-row>
      <v-row dense>
         <v-col cols="12" sm="8">
           <div>
            <v-img v-if="ship && ship.images" min-height="400" max-height="460" :src="ship.images.big" @error="imageError = true">
              <template v-slot:placeholder>
                <v-row
                  v-if="!imageError"
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                <v-progress-circular
                  indeterminate
                  color="blue"
                ></v-progress-circular>
                </v-row>
                <v-img v-if="imageError" max-height="400" contain :src="require('../assets/images/no-img.png')" />
              </template>
              <v-btn icon style="position: absolute; top: 16px; right: 16px;">
                <v-icon color="yellow">{{ship.flagship ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
              <v-btn v-if="ship.userId === user.userId" icon style="position: absolute; bottom: 16px; right: 16px;" @click="shipPropsDialog = true" elevation="2" fab small>
                <v-icon color="orange" small>fas fa-pen</v-icon>
              </v-btn>
            </v-img>
           </div>
          <div class="d-flex mt-4">
            <div>{{ship.name}}</div>
            <v-spacer />
            <div class="mr-2">from</div>
            <v-hover v-slot="{ hover }">
              <div :class="{ 'custom-anchor': hover }" style="cursor: pointer;" @click="manufacturerDetail">{{ship.manufacturerName}}</div>
            </v-hover>
          </div>
          <div class="mt-4">{{ship.description}}</div>
          <v-divider class="my-4"/>
          <v-data-table
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="userHeaders"
            :items="userShips"
            disable-pagination
            hide-default-footer
            dense
          >
          </v-data-table>
        </v-col>
        <v-col cols="12" sm="4">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in shipMeta"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="shipPropsDialog" persistentmax-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update ship props</span>
        </v-card-title>
        <v-card-text>
          <span>After you klicked "Update ship" down below, your ship data will be updated (if it was successful)</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="9">
                <v-text-field v-model="editShip.shipName" label="Ship name" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="editShip.price" label="Ship price" prefix="$" hint="Emty field = rsi standard price"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="editShip.images.small" label="Ship preview image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editShip.images.big" label="Ship image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.lti" label="LTI"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.warbond" label="Warbond"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.flagship" label="Flagship"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteShip">Delete ship</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shipPropsDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="editShipProps">Update ship</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar.vue';

  export default {
    name: 'HangarShipDetail',

    components: {
      Topbar
    },

    props: {
      hangarShipId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        navbarTitle: 'Owner Ship detail',
        shipMeta: [],
        headers: [
          {
            text: 'Type',
            align: 'start',
            filterable: false,
            value: 'name',
          },
          { text: 'Value', value: 'value' },
        ],
        sortBy: 'quantity',
        sortDesc: true,
        userHeaders: [
          {
            text: 'Other owners of',
            align: 'start',
            filterable: false,
            value: 'userName',
          },
          { text: 'Anzahl', value: 'quantity' }
        ],
        imageError: false,
        shipPropsDialog: false,
        editShip: {
          images: {
            small: '',
            big: ''
          },
          shipName: '',
          lti: '',
          price: '',
          warbond: '',
          flagship: ''
        },
        shipMeta: []
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      ship() {
        return this.$store.getters['hangar/getShip'];
      },
      userShips() {
        return this.$store.getters['hangar/getUserShips'];
      }
    },

    watch: {
      ship(ship) {
        const shipMetaOrder = {
          type: 0,
          length: 1,
          beam: 2,
          height: 3,
          size: 4,
          mass: 5,
          cargocapacity: 6,
          scm_speed: 7,
          afterburner_speed: 8,
          min_crew: 9,
          max_crew: 10,
          pitch_max: 11,
          yaw_max: 12,
          roll_max: 13,
          xaxis_acceleration: 14,
          yaxis_acceleration: 15,
          zaxis_acceleration: 16,
          price: 17,
          production_status: 18,
          lti: 19,
          warbound: 20,
          flagship: 21
        }
        if (!ship) {return;}
        for (const key in ship) {
          if (Object.hasOwnProperty.call(ship, key)) {
            if (!['_id', 'shipId', 'description', 'manufacturerId', 'manufacturerName', 'images', 'name', 'url', '__v', 'userId', 'userName', 'hangarShipId', 'shipName'].includes(key)) {
              switch (key) {
                case 'length':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'beam':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'height':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'cargocapacity':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' SCU', order: shipMetaOrder[key]});
                  break;
                case 'mass':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' kg', order: shipMetaOrder[key]});
                  break;
                case 'price':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' Dollar', order: shipMetaOrder[key]});
                  break;
                case 'lti':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                case 'warbound':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                case 'flagship':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                default:
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key], order: shipMetaOrder[key]});
                  break;
              }
            }
          }
        }
        this.shipMeta.sort((a, b) => a.order - b.order);

        const noRefShip = JSON.parse(JSON.stringify(ship))
        this.editShip = {
          images: noRefShip.images,
          shipName: noRefShip.shipName,
          lti: noRefShip.lti,
          price: noRefShip.price,
          warbond: noRefShip.warbond,
          flagship: noRefShip.flagship
        }
        this.userHeaders[0].text = 'Other owners of ' + ship.name;
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.imageError = false;
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        await this.$store.dispatch('hangar/getShip', this.hangarShipId);
        this.$store.dispatch('loading', false, {root: true});
      },
      async editShipProps() {
        await this.$store.dispatch('hangar/updateShips', [{hangarShipId: this.ship.hangarShipId, ...this.editShip}]);
        this.shipPropsDialog = false;
        this.$swal({
          toast: true,
          timer: 3000,
          position: 'top-end',
          title: 'success',
          icon: 'success',
          showConfirmButton: false
        });
        await this.loadItems();
      },
      async deleteShip() {
         this.$swal({
          title: 'Delete ship?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              this.shipPropsDialog = false;
              await this.$store.dispatch('hangar/deleteShips', [this.ship.hangarShipId]);
              this.$router.push({name: 'hangar', query: {userId: this.ship.userId}});
              this.$swal({
                toast: true,
                timer: 3000,
                position: 'top-end',
                title: 'success',
                icon: 'success',
                showConfirmButton: false
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      manufacturerDetail() {
        this.$router.push({name: 'ManufacturerDetail', query: {manufacturerId: this.ship.manufacturerId}});
      },
      userDetail(userId) {
        this.$router.push({name: 'user', query: {userId}});
      }
    }
  };
</script>

<style scoped>
.custom-anchor {
  color: skyblue;
  text-decoration: underline;
}
</style>
